import * as Yup from 'yup';

import {
  EMAIL_ERROR_MESSAGES,
  IMO_ERROR_MESSAGES,
  LOCATION_ERROR_MESSAGES,
  PASSWORD_ERROR_MESSAGES,
} from './errorMessageConstants';

export const emailValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(EMAIL_ERROR_MESSAGES.email)
      .nullable()
      .required(EMAIL_ERROR_MESSAGES.required),
  });

export const passwordValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .max(255, PASSWORD_ERROR_MESSAGES.maxCharacters)
      .required(PASSWORD_ERROR_MESSAGES.required),
  });

export const locationValidationSchema = () =>
  Yup.object().shape({
    location: Yup.object()
      .required(LOCATION_ERROR_MESSAGES.required)
      .nullable(),
  });

export const imoValidationSchema = () =>
  Yup.object().shape({
    imo: Yup.string()
      .max(7, IMO_ERROR_MESSAGES.maxCharacters)
      .required(IMO_ERROR_MESSAGES.required)
      .nullable(),
  });

export const getCombinedValidationSchema = (schemas) => {
  const validSchemas = schemas.filter((schema) => schema instanceof Yup.object);

  if (validSchemas.length === 0) {
    throw new Error('No valid schemas found to combine.');
  }

  return validSchemas.reduce(
    (combinedSchema, schema) => combinedSchema.concat(schema),
    Yup.object(),
  );
};
