import { Box, CircularProgress, Tabs, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import BasicTable from '../../components/BasicTable';
import CustomTabPanel from '../../components/CustomTabPanel';
import Error from '../../components/Error';
import NoData from '../../components/NoData';
import PopUp from '../../components/PopUp';
import RequestDetailsDialog from '../../components/RequestDetailsDialog';
import SectionHeader from '../../components/SectionHeader';
import Wrapper from '../../components/Wrapper';
import { fetch as fetchLocations } from '../../slices/locations';
import {
  fetch as fetchRequests,
  setCurrentRequest,
  clearCurrentRequest,
} from '../../slices/requests';
import { useDispatch, useSelector } from '../../store';

const HomePage = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const {
    pendingRequests,
    finishedRequests,
    requestsInProgress,
    request,
    isLoading,
    error,
  } = useSelector((state) => state.requests);
  const { locations } = useSelector((state) => state.locations);

  useEffect(() => {
    dispatch(fetchRequests());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);

  const handleFetchRequests = () => {
    dispatch(fetchRequests());
  };

  const renderTable = (data, onClickHandler) => {
    if (data && data.length > 0 && !isLoading) {
      return (
        <BasicTable requests={data} onActionButtonClick={onClickHandler} />
      );
    }
    if (data && data.length === 0 && !isLoading) {
      return <NoData message="Nenhuma solicitaçāo encontrada" />;
    }

    if (error) {
      return <Error onClick={() => handleFetchRequests()} />;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const renderPendingRequestTable = () => {
    return renderTable(pendingRequests, (item) =>
      dispatch(setCurrentRequest(item)),
    );
  };

  const renderFinishedRequestTable = () => {
    return renderTable(finishedRequests, (item) =>
      dispatch(setCurrentRequest(item)),
    );
  };

  const renderInProgressRequestTable = () => {
    return renderTable(requestsInProgress, (item) =>
      dispatch(setCurrentRequest(item)),
    );
  };

  const handleCloseRequestDetails = () => {
    dispatch(clearCurrentRequest());
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Solicitaçōes | Cabnave</title>
      </Helmet>
      <Wrapper>
        <SectionHeader
          title="Solicitaçōes"
          description="Lista de solicitações"
          isPrimary
        />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="custom tabs"
              variant="fullWidth"
            >
              <Tab var label="PENDENTES" />
              <Tab label="EM CURSO" />
              <Tab label="FINALIZADOS" />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <Box sx={{ mt: 3 }}>{renderPendingRequestTable()}</Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Box sx={{ mt: 3 }}>{renderInProgressRequestTable()}</Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <Box sx={{ mt: 3 }}>{renderFinishedRequestTable()}</Box>
          </CustomTabPanel>
        </Box>

        {request && (
          <PopUp setIsVisible={() => handleCloseRequestDetails()} isVisible>
            <RequestDetailsDialog
              locations={locations}
              onGoBack={() => handleCloseRequestDetails()}
            />
          </PopUp>
        )}
      </Wrapper>
    </>
  );
};

export default HomePage;
