import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { initialize, login as userLogin, logout } from '../slices/users';
import { useDispatch } from '../store';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const dispatch = useDispatch();

  const { user, isAuthenticated, isInitialized } = useSelector(
    (state) => state.users,
  );

  const login = async (username, password) => {
    await dispatch(userLogin({ username, password }));
    await dispatch(initialize());
  };

  useEffect(() => {
    dispatch(initialize());
  }, []);

  const contextValue = useMemo(
    () => ({
      user,
      isAuthenticated,
      isInitialized,
      platform: 'JWT',
      login,
      logout,
    }),
    [user, isAuthenticated, isInitialized, login],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
