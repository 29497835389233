import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import * as React from 'react';

import translationDict from '../../constants/translationDict';
import { requestChatSession } from '../../slices/chat';
import {
  approveEntry,
  rejectEntry,
  approveExit,
  rejectExit,
  clearError,
  clearSuccess,
  fetchById,
  startWork,
  finishWork,
  finalizeOrder,
  clearActionSuccess,
  clearActionError,
  addLocationOrDate,
  clearAddLocationOrDateSuccess,
  clearAddLocationOrDateError,
  fetchFiles,
} from '../../slices/requests';
import { useDispatch, useSelector } from '../../store';
import STATUS from '../../utils/constants';
import translateKeys from '../../utils/translateKeys';
import {
  getCombinedValidationSchema,
  locationValidationSchema,
} from '../../yupValidation';
import ActivityControl from '../ActivityControl';
import AdditionalInformation from '../AdditionalInformation';
import CustomButton from '../Button/CustomButton';
import ConfirmationPopUpDialog from '../ConfirmationPopUpDialog';
import CustomSnackBar from '../CustomSnackBar';
import DocumentsList from '../DocumentsList';
import FileList from '../FileList';
import InformationTable from '../InformationTable';
import AutocompleteInput from '../Inputs/Autocomplete';
import TextInput from '../Inputs/TextInput';
import RequestStatus from '../RequestStatus';
import RequestStatusTable from '../RequestStatusTable';
import SectionHeader from '../SectionHeader';
import ServicesInformation from '../ServicesInformation';

const RequestDetailsDialog = ({ locations, onGoBack }) => {
  const dispatch = useDispatch();
  let formikRef;

  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openUpdateRequestStatusModal, setOpenUpdateRequestStatusModal] =
    useState(false);
  const { chat, isLoading: isLoadingOpenChat } = useSelector(
    (state) => state.chat,
  );
  const [openChatAction, setOpenChatAction] = useState(false);
  const {
    error,
    isLoadingApproveOrDeny: isLoading,
    isLoadingAction,
    success,
    request,
    successAction,
    errorAction,
    successLocation,
    isLoadingLocation,
    errorLocation,
    files,
    isLoadingFiles,
  } = useSelector((state) => state.requests);
  const [entryDate, setEntryDate] = useState(
    request && request.entrance_date ? dayjs(request.entrance_date) : null,
  );
  const [exitDate, setExitDate] = useState(
    request && request.exit_date ? dayjs(request.exit_date) : null,
  );

  const updateRequest = useCallback(async () => {
    await dispatch(fetchById(request));
  }, [success, successAction]);

  const shouldRenderEntry = () => {
    return request.entrance_status.every((item) => item.status === 'accepted');
  };

  const updateAndClear = async () => {
    await updateRequest();
    dispatch(clearSuccess());
  };

  const handleRejectRequest = async () => {
    if (shouldRenderEntry()) {
      await dispatch(rejectExit(request));
      setOpenRejectModal(false);
      await updateAndClear();
    } else {
      await dispatch(rejectEntry(request));
      setOpenRejectModal(false);
      await updateAndClear();
    }
  };

  const handleApproveRequest = async () => {
    if (shouldRenderEntry()) {
      await dispatch(approveExit(request));
      setOpenApproveModal(false);
      await updateAndClear();
    } else {
      await dispatch(approveEntry(request));
      setOpenApproveModal(false);
      await updateAndClear();
    }
  };

  const handleUpdateRequestStatus = async () => {
    if (request.status === STATUS.PENDING) {
      await dispatch(startWork(request));
    }
    if (request.status === STATUS.PROGRESS) {
      await dispatch(finishWork(request));
    }
    if (request.status === STATUS.HOLDING) {
      await dispatch(finalizeOrder(request));
    }
    setOpenUpdateRequestStatusModal(false);
    await updateAndClear();
  };

  const getStatusButton = (data) => {
    const isAllApproved = (statusArray) =>
      statusArray.every((item) => item.status === 'accepted');
    const hasPending = (statusArray) =>
      statusArray.some((item) => item.status === 'pending');

    if (isAllApproved(data)) {
      return false;
    }

    if (hasPending(data)) {
      return true;
    }

    return null;
  };

  const renderActivityControlButton = () => {
    switch (request.status) {
      case STATUS.PROGRESS:
        return (
          <ActivityControl
            title="Terminar Trabalho"
            color="warning"
            onClick={() => setOpenUpdateRequestStatusModal(true)}
          />
        );
      case STATUS.HOLDING:
        return (
          <ActivityControl
            title="Finalizar Pedido"
            color="warning"
            onClick={() => setOpenUpdateRequestStatusModal(true)}
            disabled={getStatusButton(request.exit_status)}
          />
        );
      default:
        return (
          <ActivityControl
            title="Iniciar Trabalho"
            color="success"
            onClick={() => setOpenUpdateRequestStatusModal(true)}
            disabled={getStatusButton(request.entrance_status)}
          />
        );
    }
  };

  const renderActivityControlSection = () => {
    if (request.status === STATUS.FINISHED) {
      return null;
    }
    return (
      <Box>
        <SectionHeader
          title="Controle de Atividades"
          description="Inicie e encerre com um toque rápido. Controle suas tarefas com facilidade."
        />
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            mt: 2,
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              width: '200px',
              '@media (max-width: 743px)': {
                width: '100%',
              },
            }}
          >
            {renderActivityControlButton()}
          </Box>
        </Box>
      </Box>
    );
  };

  const initialValues = {
    location: request.location.id ? request.location : null,
    locationDescription: request.location.value,
    entryDate: request.entrance_date ? request.entrance_date : null, // optional
    exitDate: request.exit_date ? request.exit_date : null, // optional
  };

  const formatDate = (dateObj) => {
    const year = new Date(dateObj).getFullYear();
    const month = new Date(dateObj).getMonth() + 1;
    const day = new Date(dateObj).getDate();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const handleSubmitForm = async (data) => {
    const fullEntryDate = entryDate ? formatDate(entryDate) : null;
    const fullExitDate = exitDate ? formatDate(exitDate) : null;

    const dataToSend = {
      ...data,
      fullEntryDate,
      fullExitDate,
    };

    await dispatch(addLocationOrDate(dataToSend));
  };

  const handleRedirectToChat = () => {
    window.open(`/conversas?hash=${chat.hash}`, '_blank', 'noreferrer');
    setOpenChatAction(false);
  };

  const handleOpenChat = async () => {
    setOpenChatAction(true);
    await dispatch(requestChatSession());
  };

  const handleExternalSubmit = () => {
    if (formikRef) {
      formikRef.handleSubmit();
    }
  };

  const combinedValidationSchema = getCombinedValidationSchema([
    locationValidationSchema(),
  ]);

  const handleRedirectToSamba = () => {
    window.open(
      `${process.env.REACT_APP_SAMBA_BASE_URL}/faturacao/pro_forma?reference_code=${request.reference_code}`,
      '_blank',
      'noreferrer',
    );
  };

  const renderDocumentList = (type) => {
    if (request.samba_documents) {
      return (
        <Box sx={{ mt: 2 }}>
          <DocumentsList documents={request.samba_documents} listType={type} />
        </Box>
      );
    }

    return null;
  };

  const renderAdditionalInformation = () => {
    if (request.observation) {
      return <AdditionalInformation request={request} />;
    }
    return null;
  };

  useEffect(() => {
    if (chat && openChatAction) {
      handleRedirectToChat();
    }
  }, [chat]);

  const renderBudgetSection = () => {
    if (request.status === STATUS.FINISHED) {
      return null;
    }
    return (
      <Box>
        <SectionHeader
          title="Orçamento do Pedido"
          description="Detalhes sobre o orçamento do pedido"
        />
        {renderDocumentList('pro-formas')}
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            mt: 2,
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              width: '200px',
              '@media (max-width: 743px)': {
                width: '100%',
              },
            }}
          >
            <ActivityControl
              title="Criar Pró-Forma"
              onClick={handleRedirectToSamba}
              disabled={false}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const renderInvoiceSection = () => {
    if (request.samba_documents) {
      const hasInvoice = request.samba_documents.filter((document) => {
        return document.type === 'invoice_receipts';
      });

      if (hasInvoice) {
        return (
          <Box>
            <SectionHeader
              title="Pagamentos"
              description="Detalhes sobre os pagamentos do pedido"
            />
            {renderDocumentList('invoice_receipts')}
          </Box>
        );
      }
    }

    return null;
  };

  useEffect(() => {
    dispatch(fetchFiles(request.id));
  }, []);

  const renderFileList = () => {
    if (files) {
      return (
        <Box>
          <SectionHeader
            title="Documentos"
            description="Detalhes sobre os documentos do cliente"
          />
          <Box sx={{ mt: 2 }}>
            <FileList files={files} isLoading={isLoadingFiles} />
          </Box>
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Box>
          <SectionHeader
            title={`Opções do Pedido #${request.reference_code}`}
            description="Opções que pode fazer no pedido"
          />
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              mt: 2,
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                width: '200px',
                '@media (max-width: 743px)': {
                  width: '100%',
                },
              }}
            >
              <CustomButton
                title="Abrir Conversa"
                color="primary"
                isLoading={isLoadingOpenChat}
                onClick={handleOpenChat}
              />
            </Box>
          </Box>
        </Box>
        {renderActivityControlSection()}
        <Box>
          <SectionHeader
            title="Estado da Solicitaçāo"
            description="Estado atual da solicitaçāo"
          />
          <Box sx={{ mt: 2 }}>
            <RequestStatus request={request} />
          </Box>
        </Box>
        {renderBudgetSection()}
        {renderInvoiceSection()}
        {renderFileList()}
        <Box>
          <SectionHeader
            title="Localizaçāo do Navio"
            description="Informar a localizaçāo do navio"
          />
          <Box sx={{ mt: 2 }}>
            <Formik
              /* eslint-disable no-return-assign */
              innerRef={(ref) => (formikRef = ref)}
              initialValues={initialValues}
              validationSchema={combinedValidationSchema}
              onSubmit={(values) => handleSubmitForm(values)}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 3,
                      flexDirection: 'column',
                    }}
                  >
                    <Box>
                      <AutocompleteInput
                        name="location"
                        options={locations || []}
                        getLabel={(location) =>
                          translateKeys(location.id, translationDict)
                        }
                        value={values.location}
                        label="Localizaçāo do Navio"
                        error={Boolean(touched.location && errors.location)}
                        helperText={touched.location && errors.location}
                        onChange={(newValue) =>
                          setFieldValue('location', newValue)
                        }
                      />
                    </Box>
                    <Box>
                      <TextInput
                        name="locationDescription"
                        error={Boolean(
                          touched.locationDescription &&
                            errors.locationDescription,
                        )}
                        label="Descriçāo da Localizaçāo (opcional)"
                        helperText={
                          touched.locationDescription &&
                          errors.locationDescription
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.locationDescription}
                      />
                    </Box>
                    <Box>
                      <SectionHeader
                        title="Data de Entrada e Saída"
                        description="Informar a data de entrada e saída do navio"
                      />
                      <Box>
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            gap: 3,
                            '@media (max-width: 743px)': {
                              flexDirection: 'column',
                            },
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                              name="entryDate"
                              format="DD/MM/YYYY"
                              value={entryDate}
                              fullWidth
                              label="Data de Entrada (opcional)"
                              onChange={(newValue) => {
                                setEntryDate(newValue);
                              }}
                            />
                            <DateField
                              name="exitDate"
                              format="DD/MM/YYYY"
                              value={exitDate}
                              fullWidth
                              label="Data de Saída (opcional)"
                              onChange={(newValue) => {
                                setExitDate(newValue);
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '200px',
                          '@media (max-width: 743px)': {
                            width: '100%',
                          },
                        }}
                      >
                        <CustomButton
                          onClick={handleExternalSubmit}
                          title="Gravar"
                          isLoading={isLoadingLocation}
                        />
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 3,
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <SectionHeader
              title="Pessoa Responsável Pelo Preenchimento do Formulário"
              description="Informações sobre a pessoa responsável pelo preenchimento do formulário"
            />
            <Box sx={{ mt: 2 }}>
              <InformationTable item={request} type="work_order_submitter" />
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <SectionHeader
              title="Detalhes do Armador"
              description="Informações sobre o armador do navio"
            />
            <Box sx={{ mt: 2 }}>
              <InformationTable item={request} type="owner" />
            </Box>
          </Box>
        </Box>
        <Box>
          <SectionHeader
            title="Detalhes do Serviço"
            description="Serviços a prestar que o cliente solicitou"
          />
          <Box sx={{ mt: 2 }}>
            <ServicesInformation services={request.services} />
          </Box>
        </Box>
        <Box>
          <SectionHeader
            title="Detalhes do Navio"
            description="Informações detalhadas do navio"
          />
          <Box sx={{ mt: 2 }}>
            <InformationTable item={request} type="ship" />
          </Box>
        </Box>

        <Box>{renderAdditionalInformation()}</Box>

        <Box>
          <SectionHeader
            title="Detalhes do Estado"
            description="Informações detalhadas do estado de aprovação"
          />
          <Box sx={{ mt: 2 }}>
            <RequestStatusTable data={request} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            '@media (max-width: 743px)': {
              flexDirection: 'column',
            },
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: 200,
              '@media (max-width: 743px)': {
                width: '100%',
              },
            }}
          >
            <CustomButton
              title="Voltar"
              shadow={false}
              type="outlined"
              color="primary"
              onClick={onGoBack}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              '@media (max-width: 743px)': {
                flexDirection: 'column',
                gap: 0,
              },
            }}
          >
            <Box
              sx={{
                width: 200,
                '@media (max-width: 743px)': {
                  width: '100%',
                  pt: 1,
                },
              }}
            >
              <CustomButton
                title={
                  shouldRenderEntry() ? 'Recusar Saída' : 'Recusar Entrada'
                }
                type="contained"
                color="error"
                isDisabled={
                  request.status !== STATUS.HOLDING &&
                  !getStatusButton(request.entrance_status)
                }
                onClick={() => setOpenRejectModal(true)}
              />
            </Box>
            <Box
              sx={{
                width: 200,
                '@media (max-width: 743px)': {
                  width: '100%',
                  pt: 1,
                },
              }}
            >
              <CustomButton
                title={
                  shouldRenderEntry() ? 'Aprovar Saída' : 'Aprovar Entrada'
                }
                color="success"
                isDisabled={
                  request.status !== STATUS.HOLDING &&
                  !getStatusButton(request.entrance_status)
                }
                onClick={() => setOpenApproveModal(true)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {shouldRenderEntry()}
      <ConfirmationPopUpDialog
        title="Confirmação"
        description={
          shouldRenderEntry()
            ? 'Tem a certeza que deseja aprovar a saída deste navio?'
            : 'Tem a certeza que deseja aprovar a entrada deste navio?'
        }
        isVisible={openApproveModal}
        setIsVisible={setOpenApproveModal}
        onCancel={() => setOpenApproveModal(false)}
        onConfirm={handleApproveRequest}
        cancelTitle="Cancelar"
        confirmTitle="Aprovar"
        isLoading={isLoading}
        color="success"
      />
      <ConfirmationPopUpDialog
        title="Confirmação"
        description={
          shouldRenderEntry()
            ? 'Tem a certeza que deseja recusar a saída deste navio?'
            : 'Tem a certeza que deseja recusar a entrada deste navio?'
        }
        isVisible={openRejectModal}
        setIsVisible={setOpenRejectModal}
        onCancel={() => setOpenRejectModal(false)}
        onConfirm={handleRejectRequest}
        cancelTitle="Cancelar"
        confirmTitle="Recusar"
        isLoading={isLoading}
        color="error"
      />
      <ConfirmationPopUpDialog
        title="Confirmação"
        description="Tem a certeza que deseja atualizar o estado da solicitação?"
        isVisible={openUpdateRequestStatusModal}
        setIsVisible={setOpenUpdateRequestStatusModal}
        onCancel={() => setOpenUpdateRequestStatusModal(false)}
        onConfirm={handleUpdateRequestStatus}
        cancelTitle="Cancelar"
        confirmTitle="Confirmar"
        isLoading={isLoadingAction}
        color="success"
      />
      <CustomSnackBar
        severity="error"
        open={Boolean(error)}
        message="Ocorreu um erro, tente novamente mais tarde!"
        onClose={() => dispatch(clearError())}
      />
      <CustomSnackBar
        severity="success"
        open={success}
        message="Açāo realizada com sucesso!"
        onClose={() => dispatch(clearSuccess())}
      />
      <CustomSnackBar
        severity="success"
        open={successAction}
        message="Açāo realizada com sucesso!"
        onClose={() => dispatch(clearActionSuccess())}
      />
      <CustomSnackBar
        severity="error"
        open={errorAction}
        message="Ocorreu um erro, tente novamente mais tarde!"
        onClose={() => dispatch(clearActionError())}
      />
      {/* Add location of the ship */}
      <CustomSnackBar
        severity="success"
        open={successLocation}
        message="Açāo realizada com sucesso!"
        onClose={() => dispatch(clearAddLocationOrDateSuccess())}
      />
      <CustomSnackBar
        severity="error"
        open={errorLocation}
        message="Ocorreu um erro, tente novamente mais tarde!"
        onClose={() => dispatch(clearAddLocationOrDateError())}
      />
    </>
  );
};

RequestDetailsDialog.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onGoBack: PropTypes.func.isRequired,
};

export default RequestDetailsDialog;
