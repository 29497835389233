import { combineReducers } from '@reduxjs/toolkit';

import chat from '../slices/chat';
import locations from '../slices/locations';
import requests from '../slices/requests';
import users from '../slices/users';

const appReducer = combineReducers({
  requests,
  users,
  locations,
  chat,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
