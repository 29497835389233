const COMMON_MESSAGE = {
  required: 'Obrigatório',
  number: 'Deve ser um número',
  maxCharacters: 'Deve conter no máximo 255 caracteres',
};

export const EMAIL_ERROR_MESSAGES = {
  required: COMMON_MESSAGE.required,
  email: 'Digite um endereço de e-mail válido',
};

export const PASSWORD_ERROR_MESSAGES = {
  required: COMMON_MESSAGE.required,
  maxCharacters: COMMON_MESSAGE.maxCharacters,
};

export const IMO_ERROR_MESSAGES = {
  required: COMMON_MESSAGE.required,
  maxCharacters: 'Dever conter no máximo 7 caracteres',
};

export const LOGOUT_ERROR_MESSAGES = {
  generic: 'Ocorreu um erro, por favor, tente novamente.',
};

export const LOCATION_ERROR_MESSAGES = {
  required: COMMON_MESSAGE.required,
};
