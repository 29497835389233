import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';

import CustomSnackBar from './components/CustomSnackBar';
import Footer from './components/Footer';
import Header from './components/Header';
import LogOffPopUpDialog from './components/LogOffPopUpDialog';
import { LOGOUT_ERROR_MESSAGES } from './errorMessageConstants';
import SlashScreen from './pages/Splash/Splash';
import routes from './routes';
import { initialize, logout } from './slices/users';
import { useSelector, useDispatch } from './store';

export default function App() {
  const [isVisible, setIsVisible] = useState(false);
  const [isSnackBarVisible, setIsSnackBarOpen] = useState(false);

  const content = useRoutes(routes);
  const { isAuthenticated, isInitialized } = useSelector(
    (state) => state.users,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signOut = async () => {
    try {
      setIsVisible(false);
      navigate('/');
      await dispatch(logout());
      await dispatch(initialize());
    } catch (err) {
      setIsSnackBarOpen(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        backgroundColor: 'background.main',
      }}
    >
      <Box>
        <Header
          isAuthenticated={isAuthenticated}
          onSignOut={() => setIsVisible(true)}
        />
        <LogOffPopUpDialog
          title="Confirmação"
          description="Tem certeza de que deseja sair?"
          isVisible={isVisible}
          primaryText="Cancelar"
          secondaryText="Sair"
          onClickPrimary={() => setIsVisible(false)}
          onClickSecondary={() => signOut()}
          setIsVisible={setIsVisible}
          secondaryTextColor="error"
        />
        <CustomSnackBar
          open={isSnackBarVisible}
          message={LOGOUT_ERROR_MESSAGES.generic}
          onClose={setIsSnackBarOpen}
          severity="error"
        />
        <Container maxWidth="xl">
          {isInitialized ? content : isAuthenticated && <SlashScreen />}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
