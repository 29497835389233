import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import translationDict from '../../constants/translationDict';
import STATUS from '../../utils/constants';
import translateKeys from '../../utils/translateKeys';
import ChipButton from '../Button/ChipButton';
import ContentCard from '../ContentCard';

const RequestStatus = ({ request }) => {
  const renderStatus = () => {
    switch (request.status) {
      case STATUS.PROGRESS:
        return (
          <ChipButton
            label={translateKeys(request.status, translationDict)}
            type="info"
          />
        );
      case STATUS.HOLDING:
        return (
          <ChipButton
            label={translateKeys(request.status, translationDict)}
            type="pending"
          />
        );
      case STATUS.FINISHED:
        return (
          <ChipButton
            label={translateKeys(request.status, translationDict)}
            type="approved"
          />
        );
      default:
        return (
          <ChipButton
            label={translateKeys('pending', translationDict)}
            type="pending"
          />
        );
    }
  };

  return (
    <ContentCard>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {renderStatus()}
      </Box>
    </ContentCard>
  );
};

RequestStatus.propTypes = {
  request: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default RequestStatus;
