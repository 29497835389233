import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';

const AutocompleteInput = ({
  options,
  value,
  label,
  helperText,
  onChange,
  name,
  error,
  getLabel,
}) => {
  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={getLabel}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          name={name}
          label={label}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
};

AutocompleteInput.defaultProps = {
  helperText: null,
  value: null,
  options: [],
  error: null,
};

AutocompleteInput.propTypes = {
  options: PropTypes.arrayOf(undefined),
  value: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  getLabel: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default AutocompleteInput;
